<template>
  <v-container class="ma-0 pa-0 mt-7 mb-0">
    <div class="d-flex align-center justify-space-between">
      <p class="section__text--title">Áreas de medicina</p>
<!--      <p v-if="$vuetify.breakpoint.smAndUp" class="field__text ">-->
<!--        <span class="field__part-text&#45;&#45;total">Total de Flashcard</span>-->
<!--        /-->
<!--        <span class="field__part-text&#45;&#45;pendientes">Pendientes</span>-->
<!--        /-->
<!--        <span class="field__part-text&#45;&#45;aprendizaje">En aprendizaje</span>-->
<!--        /-->
<!--        <span class="field__part-text&#45;&#45;dominadas">Dominadas</span>-->
<!--      </p>-->
    </div>
    <v-row v-if="!loading" dense flat class="mt-0">
      <v-col
        v-for="(area, index) in areas"
        :key="index"
        class="mb-4 flashcard-col"
        cols="12"
      >
        <AreasItem
          :area="area"
          @click="goToArea(area)"
        />
      </v-col>
    </v-row>
    <div v-else class="progress__wrapper d-flex justify-center">
      <v-progress-circular indeterminate/>
    </div>
  </v-container>
</template>

<script>
import AreasItem from '@/components/units/AreasItem'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Areas',
  components: {
    AreasItem
  },
  data () {
    return {
      areas: [],
      loading: false
    }
  },
  computed: {
    ...mapState('flashcard', ['flashcardUnits']),
    allAreasIds () {
      return this.flashcardUnits.map(item => item.id).filter(item => item !== -1)
    }
  },
  methods: {
    ...mapActions('flashcard', ['fetchFlashcardsUnits']),
    // description (areaName) {
    //   return this.states.find(item => item.nombre === areaName)
    // },
    goToArea (area) {
      // if (area.pendientes) {
      if (area.id === -1) {
        this.$router.push({ name: 'FlashcardSimulator', params: { tipo: 'areas', ids: this.allAreasIds.join(',') } })
      } else {
        this.$router.push({ name: 'UnitsAreasSpecialities', params: { eid: area.id } })
      }
      // } else {
      //   this.$root.$confirm({
      //     title: 'Felicidades!',
      //     message: 'Por ahora no tienes flashcards pendientes en esta área. Anda practicando otras mientras se desbloquean tus flashcard.',
      //     acceptText: 'Ok'
      //   })
      // }
    }
  },
  async created () {
    this.loading = true
    await this.fetchFlashcardsUnits()
    this.areas = [{ nombre: 'Todas las áreas', id: -1 }, ...this.flashcardUnits]
    this.loading = false
  }
}
</script>
