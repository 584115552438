<template>
  <UnitsFrame>
    <PendingCard/>
    <Areas
      :areas="areas"
    />
  </UnitsFrame>
</template>

<script>
import PendingCard from '@/components/PendingCard.vue'
import UnitsFrame from '@/components/units/UnitsFrame'
import Areas from '@/components/units/Areas.vue'

export default {
  name: 'UnitsAreas',
  data () {
    return {
      areas: []
    }
  },
  components: {
    UnitsFrame,
    PendingCard,
    Areas
  }
}
</script>
