<template>
  <v-card
    v-on="$listeners"
    class="d-flex align-center justify-space-between area__card flashcard-item"
    height="84"
  >
    <div class="section__text--title area__name text-start">{{ area.nombre }}</div>
<!--    <div class="section__text&#45;&#45;title d-flex">-->
<!--      <div style="font-weight: 400; color: #000" class="mr-1">{{ description.total }}</div>-->
<!--      /-->
<!--      <div style="font-weight: 400; color: #DB402C" class="mx-1">{{ description.pendientes }}</div>-->
<!--      /-->
<!--      <div style="font-weight: 400; color: #F3AC71" class="mx-1">{{ description.en_aprendizaje }}</div>-->
<!--      /-->
<!--      <div style="font-weight: 400; color: #0683DA" class="ml-1">{{ description.masterizadas }}</div>-->
<!--    </div>-->
  </v-card>
</template>

<script>
export default {
  name: 'AreasItem',
  props: {
    area: {
      type: Object,
      default: function () {
        return {
          id: null,
          nombre: ''
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.area__name {
  flex-basis: 50%;
}
.area__card {
  padding: 20px 30px;
}
@media (max-width: 375px) {
  .area__card {
    flex-direction: column;
    /* padding-top: 10px !important; */
    padding-bottom: 20px;
    height: 95px !important;
  }
  .area__name {
    text-align: center !important;
  }
}
</style>
